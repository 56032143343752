import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import PLANS_QUERY from '@/graphql/queries/plans.gql';
import PlanList from '@/components/Plans/PlanList/PlanList.vue';
import PlansReport from '@/components/PlansReport/PlansReport.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
export default {
  name: 'Plans',
  components: { Btn, PlanList, Modal, PlansReport },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    PLANS_QUERY,
    selected: [],
    currentUser: null,
  }),
  computed: {
    showGenerateReportBtn() {
      return accessByPermissions(PERMISSIONS.GENERATE_REPORT, this.currentUser);
    },
    disableReportBtn() {
      return this.selected.length === 0;
    },
  },
  methods: {
    onSelect(items) {
      this.selected = items;
    },
    onReset() {
      this.selected = [];
    },
    getPlans(data) {
      return data && data.plans
        ? data.plans.map(item => {
            return { ...item, isCurrent: item.isCurrent ? 'Yes' : 'No' };
          })
        : [];
    },
  },
};

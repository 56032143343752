import Btn from '@/components/Btn/Btn.vue';
import DataTableVS from '@/components/DataTableVS/DataTableVS.vue';
import DELETE_COMPONENT_OF_NEED_MUTATION from '@/graphql/mutations/deleteComponentOfNeed.gql';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import eventHub from '@/utils/eventHub';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import Modal from '@/components/Modal/Modal.vue';
import ActionsMenu from '@/components/ActionsMenu/ActionsMenu.vue';
import { calculateBudget } from '@/services/componentBudget';
import CLONE_COMPONENT_OF_NEEDS_MUTATION from '@/graphql/mutations/cloneComponentOfNeed.gql';
import EditComponent from '@/components/Plans/PlanForm/Components/ActiveComponents/EditComponent/EditComponent.vue';
import COMPONENTS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import CompleteComponent from '@/components/Plans/PlanForm/Components/ActiveComponents/CompleteComponent/CompleteComponent.vue';
import flaggedComponentMixin from '@/mixins/flaggedComponentMixin';

export default {
  name: 'ComponentList',
  mixins: [flaggedComponentMixin],
  components: {
    Btn,
    ConfirmationModal,
    Modal,
    EditComponent,
    CompleteComponent,
    ActionsMenu,
    'data-table-vs': DataTableVS,
  },
  props: {
    plan: { type: Object, default: null },
    components: { type: Array, default: [] },
    selectedHeaders: { type: Array, default: [] },
    loading: { type: Boolean },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    currentUser: null,
    flagFilterModel: [],
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Flag',
          value: 'flag',
          dataType: 'string',
          width: '40px',
          fixed: true,
          type: 'slot',
        },
        {
          text: 'Facility',
          value: 'facilityName',
          type: 'select',
          dataType: 'string',
          fixed: true,
          width: '150px',
        },
        { text: 'System', value: 'systemName', type: 'select', dataType: 'string', width: '180px' },
        {
          text: 'Component',
          value: 'componentName',
          type: 'select',
          dataType: 'string',
          width: '180px',
        },
        { text: 'Year', value: 'yearForImprovement', type: 'select', dataType: 'year', width: '100px' },
        { text: 'Quantity', value: 'quantityOfComponents', type: 'number', dataType: 'number', width: '80px' },
        { text: 'Unit', value: 'unitName', type: 'select', dataType: 'string', width: '100px' },
        { text: 'Cost Per Unit', value: 'costPerUnit', type: 'number', dataType: 'currency', width: '100px' },
        { text: 'Base Amount', value: 'baseAmount', type: 'number', dataType: 'currency', width: '100px' },
        {
          text: 'Component Subtotal',
          value: 'componentSubtotal',
          type: 'number',
          dataType: 'currency',
          width: '100px',
        },
        { text: 'Soft Cost', value: 'softCost', width: '70px' },
        { text: 'Budget Amount', value: 'budgetAmount', type: 'number', dataType: 'currency', width: '120px' },
        { text: 'Budgetary Notes', value: 'budgetaryNotes', type: 'text', dataType: 'longstring', width: '180px' },
        { text: 'Description', value: 'description', type: 'text', dataType: 'longstring', width: '180px' },
        { text: 'Condition', value: 'conditionName', type: 'select', dataType: 'string', width: '100px', noSort: true },
        { text: 'Location', value: 'locationOfComponent', type: 'text', dataType: 'longstring', width: '130px' },
        { text: 'Image', value: 'componentUrl', width: '100px' },
        { text: 'Funding Source', value: 'fundingSourceName', type: 'select', dataType: 'string', width: '100px' },
        { text: 'Internal Notes', value: 'internalNotes', type: 'text', dataType: 'longstring', width: '180px' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '53px',
          fixed: true,
          permissions: [PERMISSIONS.EDIT_PLAN_COMPONENT, PERMISSIONS.DELETE_PLAN_COMPONENT],
        },
      ];
    },
    displayedItems() {
      return this.components
        ? this.components.map(component => {
            const budget = calculateBudget(component, this.plan);
            return {
              ...component,
              facilityName: (component.facility && component.facility.name) || '',
              systemName: (component.system && component.system.name) || '',
              componentName: (component.component && component.component.name) || '',
              unitName: (component.unit && component.unit.name) || '',
              conditionName: (component.conditionAssessment && component.conditionAssessment.name) || '',
              fundingSourceName: (component.fundingSource && component.fundingSource.name) || '',
              ...budget,
              actions: [
                accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Edit',
                  modal: {
                    type: 'common',
                    props: {
                      title: 'Edit Component',
                      width: '812px',
                    },
                    content: {
                      componentName: EditComponent,
                      props: {
                        plan: this.plan,
                        data: component,
                      },
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Complete',
                  modal: {
                    type: 'common',
                    props: {
                      title: 'Complete Component',
                      width: '480px',
                    },
                    content: {
                      componentName: CompleteComponent,
                      props: {
                        plan: this.plan,
                        data: component,
                      },
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Clone',
                  modal: {
                    props: {
                      mutation: CLONE_COMPONENT_OF_NEEDS_MUTATION,
                      variables: { id: component.id },
                      title: 'Clone Component',
                      message: 'Are you sure you want to clone component?',
                      okBtn: { label: 'Clone' },
                      update: this.onCloneComponent(component),
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.DELETE_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Delete',
                  modal: {
                    props: {
                      mutation: DELETE_COMPONENT_OF_NEED_MUTATION,
                      variables: { id: component.id },
                      title: 'Delete Component',
                      message: 'Are you sure you want to delete component?',
                      okBtn: { label: 'Delete' },
                      update: this.onDelete(component),
                    },
                  },
                },
              ],
            };
          })
        : [];
    },
    availableHeaders() {
      const result = this.headers.filter(h => {
        return !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser));
      });
      this.$emit('initHeaders', result);
      return result;
    },
    displayedHeaders() {
      return this.availableHeaders.filter((h, index) => {
        return this.selectedHeaders ? this.selectedHeaders.includes(index) : h;
      });
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_PLAN_COMPONENT, this.currentUser);
    },
    flagFilterIcon() {
      if (this.flagFilterModel.length === 0) {
        return 'fa-star-half-alt';
      }
      if (this.flagFilterModel[0] === true) {
        return 'fas fa-star';
      }
      if (this.flagFilterModel[0] === false) {
        return 'far fa-star';
      }
    },
  },
  methods: {
    onCloneComponent() {
      return (store, { data: { cloneComponentOfNeed } }) => {
        if (cloneComponentOfNeed) {
          const data = store.readQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: cloneComponentOfNeed.status,
            },
          });
          if (data) {
            data.componentsOfNeed.unshift(cloneComponentOfNeed);
            store.writeQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: {
                planId: this.plan.id,
                status: cloneComponentOfNeed.status,
              },
              data,
            });
          }
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: 'Component was cloned successfully!',
          });
        }
      };
    },
    onDelete(item) {
      return (store, { data: { deleteComponentOfNeed } }) => {
        if (deleteComponentOfNeed) {
          const data = store.readQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: item.status,
            },
          });
          if (data) {
            data.componentsOfNeed = data.componentsOfNeed.filter(component => {
              return component.id !== item.id;
            });
            store.writeQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: {
                planId: this.plan.id,
                status: item.status,
              },
              data,
            });
          }
          eventHub.$emit('show-snackbar', { color: 'success', text: 'Component was deleted successfully!' });
        }
      };
    },
  },
};

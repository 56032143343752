import FacilityForm from '@/components/Clients/ClientForm/Facilities/FacilityForm/FacilityForm.vue';
import UPDATE_FACILITY_MUTATION from '@/graphql/mutations/updateFacility.gql';
import eventHub from '@/utils/eventHub';

export default {
  name: 'EditFacility',
  components: { FacilityForm },
  props: {
    client: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data: function() {
    return {
      UPDATE_FACILITY_MUTATION,
      facility: (this.data && { ...this.data }) || {
        name: '',
        squareFootage: null,
        age: '',
        occupancy: null,
        capacity: null,
        use: '',
        siteSize: null,
        replacementCost: null,
        photoFile: null,
        summary: '',
      },
    };
  },
  watch: {
    data(value) {
      this.facility = { ...value };
    },
  },
  methods: {
    onSubmit(mutate) {
      this.facility = { ...this.facility };
      this.$nextTick(() => {
        mutate();
      });
    },
    updateFacilities(store, { data: { updateFacility } }) {
      if (updateFacility) {
        const index = this.client.facilities.findIndex(c => c.id === updateFacility.id);
        if (index !== -1) {
          this.client.facilities[index] = updateFacility;
          this.client.facilities = [...this.client.facilities];
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Facility ${updateFacility.name} was updated successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};

import Btn from '@/components/Btn/Btn.vue';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';
import DefinitionList from '@/components/DefinitionList/DefinitionList.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import FacilityList from '@/components/FacilityList/FacilityList.vue';
import FileList from '@/components/FileList/FileList.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
export default {
  name: 'ClientInfo',
  components: { Btn, ExpansionPanelHeader, DefinitionList, DataTable, FacilityList, FileList },
  props: {
    client: { type: Object, default: null },
    hidePlans: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    currentUser: null,
    expanded: 0,
    error: null,
    generalInfoLabels: [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'District/Department Number',
        value: 'district',
      },
      {
        label: 'Address Line',
        value: 'address',
      },
      {
        label: 'City',
        value: 'city',
      },
      {
        label: 'State',
        value: 'state',
      },
      {
        label: 'Zip Code',
        value: 'zip',
      },
      {
        label: 'County',
        value: 'county',
      },
      {
        label: 'ICS Responsible Office',
        value: 'icsOffice.name',
      },
      {
        label: 'Client Type',
        value: 'clientType.name',
      },
      {
        label: 'Client Summary',
        value: 'description',
        type: 'longstring',
      },
    ],
    contactInfoLabels: [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Job Title',
        value: 'jobTitle',
      },
      {
        label: 'Phone',
        labelName: 'type.name', // key in model
        value: 'phones',
        type: 'list',
        valueName: 'phone', // key in model
      },
      {
        label: 'Email',
        value: 'email',
      },
      {
        label: 'Facility/Location of Contact',
        value: 'location',
      },
      {
        label: 'Number of Year With Employee',
        value: 'countYearsTogether',
      },
    ],
    planHeaders: [
      {
        text: 'Name',
        value: 'name',
        type: 'text',
        dataType: 'string',
        width: '150px',
        fixed: true,
      },
      {
        text: 'Current Plan',
        value: 'isCurrent',
        type: 'select',
        dataType: 'string',
        width: '100px',
        options: ['Yes', 'No'],
      },
      {
        text: 'Date of Creation',
        value: 'dateOfCreation',
        type: 'date',
        dataType: 'date',
        width: '150px',
      },
      { text: 'Plan Type', value: 'planType', type: 'select', dataType: 'string', width: '100px' },
      { text: 'Creator', value: 'creatorName', type: 'select', dataType: 'string', width: '120px' },
      { text: 'Editor', value: 'editorName', type: 'select', dataType: 'string', width: '150px' },
      { text: 'Last Updated', value: 'updatedAt', type: 'date', dataType: 'date', width: '180px' },
    ],
  }),
  computed: {
    canViewPlans() {
      return accessByPermissions(PERMISSIONS.VIEW_PLAN, this.currentUser);
    },
    facilities() {
      return this.client ? this.client.facilities : [];
    },
    documents() {
      return this.client && Array.isArray(this.client.documents)
        ? this.client.documents.map(item => {
            return {
              link: item.documentUrl,
              name: item.name || item.documentUrl,
            };
          })
        : [];
    },
    displayedPlans() {
      return this.client && this.client.plans
        ? this.client.plans.map(plan => {
            return {
              ...plan,
              creatorName: (plan.createdBy && plan.createdBy.username) || '',
              planType:
                (plan.financialInfo && plan.financialInfo.typeOfPlan && plan.financialInfo.typeOfPlan.name) || '',
              editorName: (plan.editedBy && plan.editedBy.username) || '',
              canViewDetails: accessByPermissions(PERMISSIONS.VIEW_PLAN, this.currentUser),
              isCurrent: plan.isCurrent ? 'Yes' : 'No',
            };
          })
        : [];
    },
  },
};

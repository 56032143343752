import Btn from '@/components/Btn/Btn.vue';
import DataTableVS from '@/components/DataTableVS/DataTableVS.vue';
import DELETE_COMPLETED_COMPONENT_MUTATION from '@/graphql/mutations/deleteComponentOfNeed.gql';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import eventHub from '@/utils/eventHub';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import Modal from '@/components/Modal/Modal.vue';
import EditComponent from '@/components/Plans/PlanForm/Components/ActiveComponents/EditComponent/EditComponent.vue';
import ActionsMenu from '@/components/ActionsMenu/ActionsMenu.vue';
import { getBaseAmount, getBudgetAmount, getComponentSubtotal } from '@/services/componentBudget';
import COMPONETS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';

export default {
  name: 'ComponentList',
  components: { Btn, ConfirmationModal, Modal, EditComponent, ActionsMenu, 'data-table-vs': DataTableVS },
  props: {
    plan: { type: Object, default: null },
    components: { type: Array, default: [] },
    loading: { type: Boolean },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    DELETE_COMPLETED_COMPONENT_MUTATION,
    currentUser: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Facility',
          value: 'facilityName',
          type: 'select',
          dataType: 'string',
          fixed: true,
          width: '150px',
        },
        {
          text: 'Component',
          value: 'componentName',
          type: 'select',
          dataType: 'string',
          width: '180px',
        },
        { text: 'Year Completed', value: 'yearCompleted', type: 'select', dataType: 'year', width: '100px' },
        { text: 'Actual Cost', value: 'actualCost', type: 'number', dataType: 'currency', width: '120px' },
        { text: 'Planned Cost', value: 'budgetAmount', type: 'number', dataType: 'currency', width: '120px' },
        { text: 'Quantity', value: 'quantityOfComponents', type: 'number', dataType: 'number', width: '100px' },
        { text: 'Location', value: 'locationOfComponent', type: 'text', dataType: 'string', width: '130px' },
        { text: 'Component Notes', value: 'componentNotes', type: 'text', dataType: 'longstring', width: '180px' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '53px',
          fixed: true,
          permissions: [PERMISSIONS.DELETE_PLAN_COMPONENT],
        },
      ];
    },
    displayedItems() {
      return this.components
        ? this.components.map(component => {
            const { quantityOfComponents, costPerUnit, yearForImprovement, softCost } = component;
            const {
              regionalCost,
              planStartYear,
              inflationCostEscalation,
              softCostPercentage,
            } = this.plan.financialInfo;

            const baseAmount = getBaseAmount(quantityOfComponents, costPerUnit, regionalCost);
            const componentSubtotal = getComponentSubtotal(
              baseAmount,
              planStartYear,
              yearForImprovement,
              inflationCostEscalation
            );
            const budgetAmount = getBudgetAmount(componentSubtotal, softCostPercentage, softCost);
            return {
              ...component,
              facilityName: (component.facility && component.facility.name) || '',
              componentName: (component.component && component.component.name) || '',
              budgetAmount: budgetAmount,
            };
          })
        : [];
    },
    displayedHeaders() {
      return this.headers.filter(
        h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser))
      );
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_PLAN_COMPONENT, this.currentUser);
    },
  },
  methods: {
    onDelete(item) {
      return (store, { data: { deleteComponentOfNeed } }) => {
        if (deleteComponentOfNeed) {
          const data = store.readQuery({
            query: COMPONETS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: item.status,
            },
          });
          data.componentsOfNeed = data.componentsOfNeed.filter(component => {
            return component.id !== item.id;
          });
          store.writeQuery({
            query: COMPONETS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: item.status,
            },
            data,
          });
          eventHub.$emit('show-snackbar', { color: 'success', text: 'Component was deleted successfully!' });
        }
      };
    },
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table-vs',{staticClass:"component-list",attrs:{"items":_vm.displayedItems,"headers":_vm.displayedHeaders,"height":"611px","item-key":"id","loading":_vm.loading,"resetBtnParams":{ show: true, isAbsolute: true }},on:{"filtered":function($event){return _vm.$emit('filtered', $event)},"mounted":_vm.setFlagFilterDefault,"reset":_vm.resetFlagFilter},scopedSlots:_vm._u([{key:"filter.flag",fn:function(ref){
var item = ref.item;
var callback = ref.callback;
return [_c('div',{staticClass:"select-wrapper",staticStyle:{"width":"97px"}},[_c('v-select',{ref:"flag_filter",attrs:{"outlined":"","dense":"","multiple":"","full-width":"","hide-details":"","clearable":"","items":['Marked', 'Unmarked'],"default":item},on:{"change":function($event){return _vm.onFlagFilter(item, callback)}},model:{value:(_vm.flagFilterModel),callback:function ($$v) {_vm.flagFilterModel=$$v},expression:"flagFilterModel"}})],1)]}},{key:"item.flag",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.themes.light.accent},on:{"click":function($event){return _vm.onFlagClick(item)}}},[_vm._v(_vm._s(item.flag ? 'fas' : 'far')+" fa-flag")])],1)]}},{key:"item.softCost",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.softCost,"ripple":false,"readonly":""}})]}},{key:"item.componentUrl",fn:function(ref){
var item = ref.item;
return [(item.componentUrl)?_c('a',{attrs:{"href":item.componentUrl,"target":"_blank"}},[_vm._v("Link to Photo")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('actions-menu',{attrs:{"actions":item.actions}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
import Btn from '@/components/Btn/Btn.vue';
import EditDialog from '@/components/EditDialog/EditDialog.vue';
import DataTableVS from '@/components/DataTableVS/DataTableVS.vue';
import { calculateBudget } from '@/services/componentBudget';
import validateRules from '@/services/validateRules';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import TruncatedTextSimple from '@/components/TruncatedTextSimple/TruncatedTextSimple.vue';
import { generateRangeArray } from '@/utils/generator';
import UNITS_QUERY from '@/graphql/queries/units.gql';
import CONDITION_QUERY from '@/graphql/queries/conditionAssessment.gql';
import FUNDING_SOURCE_QUERY from '@/graphql/queries/fundingSource.gql';

export default {
  name: 'ComponentList',
  components: { Btn, EditDialog, NumberTextField, TruncatedTextSimple, 'data-table-vs': DataTableVS },
  props: {
    plan: { type: Object, default: null },
    components: { type: Array, default: [] },
    loading: { type: Boolean },
    readonly: { type: Boolean },
    changes: { type: Array },
    selected: { type: Array },
    filtered: { type: Array, default: [] },
    selectedHeaders: { type: Array, default: [] },
    showSelect: { type: Boolean, default: false },
  },
  apollo: {
    units: {
      query: UNITS_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load unit list.' }
          : error;
      },
    },
    conditionAssessment: {
      query: CONDITION_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load condition assessment list.' }
          : error;
      },
    },
    fundingSource: {
      query: FUNDING_SOURCE_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load funding source list.' }
          : error;
      },
    },
  },
  data: vm => {
    const startYear = vm.plan && vm.plan.financialInfo && parseInt(vm.plan.financialInfo.planStartYear);
    const years = startYear ? generateRangeArray(startYear, startYear + 19) : [];
    return {
      valid: false,
      editableItem: null,
      editableField: null,
      costRules: [
        v => (v !== null && v !== '') || 'Cost Per Unit is Required',
        validateRules.positiveNumber,
        validateRules.floatNumber(2),
      ],
      years: years,
      yearForImprovementRules: [v => !!v || 'Year Set for Improvement is Required'],
      quantityOfComponentsRules: [v => (v !== null && v !== '') || 'Quantity of Components is Required'],
      unitRules: [v => !!v || 'Unit is Required'],
      units: null,
      conditionAssessment: null,
      fundingSource: null,
      error: null,
      flagFilterModel: [],
    };
  },
  mounted() {
    this.$emit('initHeaders', this.headers);
  },
  computed: {
    headers() {
      return [
        {
          text: 'Flag',
          value: 'flag',
          dataType: 'string',
          width: '40px',
          fixed: true,
          type: 'slot',
        },
        {
          text: 'Facility',
          value: 'facilityName',
          type: 'select',
          dataType: 'string',
          fixed: true,
          width: '150px',
        },
        { text: 'System', value: 'systemName', type: 'select', dataType: 'string', width: '180px' },
        {
          text: 'Component',
          value: 'componentName',
          type: 'select',
          dataType: 'string',
          width: '180px',
        },
        { text: 'Year', value: 'yearForImprovement', type: 'select', dataType: 'year', width: '100px' },
        { text: 'Quantity', value: 'quantityOfComponents', type: 'number', dataType: 'number', width: '80px' },
        { text: 'Unit', value: 'unitName', type: 'select', dataType: 'string', width: '100px' },
        { text: 'Cost Per Unit', value: 'costPerUnit', type: 'number', dataType: 'currency', width: '100px' },
        { text: 'Base Amount', value: 'baseAmount', type: 'number', dataType: 'currency', width: '100px' },
        {
          text: 'Component Subtotal',
          value: 'componentSubtotal',
          type: 'number',
          dataType: 'currency',
          width: '100px',
        },
        { text: 'Soft Cost', value: 'softCost', width: '70px' },
        { text: 'Budget Amount', value: 'budgetAmount', type: 'number', dataType: 'currency', width: '120px' },
        { text: 'Budgetary Notes', value: 'budgetaryNotes', type: 'text', dataType: 'longstring', width: '180px' },
        { text: 'Description', value: 'description', type: 'text', dataType: 'longstring', width: '180px' },
        { text: 'Condition', value: 'conditionName', type: 'select', dataType: 'string', width: '100px', noSort: true },
        { text: 'Location', value: 'locationOfComponent', type: 'text', dataType: 'longstring', width: '130px' },
        { text: 'Image', value: 'componentUrl', width: '100px' },
        { text: 'Funding Source', value: 'fundingSourceName', type: 'select', dataType: 'string', width: '100px' },
        { text: 'Internal Notes', value: 'internalNotes', type: 'text', dataType: 'longstring', width: '180px' },
      ];
    },
    displayedItems() {
      return this.components
        ? this.components.map(component => {
            return {
              ...component,
              facilityName: (component.facility && component.facility.name) || '',
              systemName: (component.system && component.system.name) || '',
              componentName: (component.component && component.component.name) || '',
              unitName: (component.unit && component.unit.name) || '',
              conditionName: (component.conditionAssessment && component.conditionAssessment.name) || '',
              fundingSourceName: (component.fundingSource && component.fundingSource.name) || '',
              ...calculateBudget(component, this.plan),
            };
          })
        : [];
    },
    displayedHeaders() {
      return this.headers.filter((h, index) => {
        return this.selectedHeaders ? this.selectedHeaders.includes(index) : h;
      });
    },
    selectedBudget() {
      return this.selected.length > 0
        ? this.selected.reduce((b, c) => {
            return b + c.budgetAmount;
          }, 0)
        : this.filtered.length > 0
        ? this.filtered.reduce((b, c) => {
            return b + c.budgetAmount;
          }, 0)
        : this.displayedItems
        ? this.displayedItems.reduce((b, c) => {
            return b + c.budgetAmount;
          }, 0)
        : 0;
    },
    footerLabel() {
      return this.filtered.length === 0 && this.selected.length === 0
        ? 'Components Budget'
        : 'Selected components Budget';
    },
    selectedState: {
      get() {
        return this.selected;
      },
      set(localState) {
        this.$emit('select', localState);
      },
    },
  },
  methods: {
    itemClass(item) {
      const changed = this.changes.find(c => c.id === item.id);
      if (changed) {
        return 'changed-row';
      }
    },
    onSaveSoftCost(item) {
      const { baseAmount, componentSubtotal, budgetAmount } = calculateBudget(item, this.plan);
      item.baseAmount = baseAmount;
      item.componentSubtotal = componentSubtotal;
      item.budgetAmount = budgetAmount;
      this.$emit('change', item, 'softCost');
    },
    onFlagClick(item) {
      item.flag = !item.flag;
      this.$emit('change', item, 'flag');
    },
    onSave(item, key) {
      if (!this.valid) return;
      if (item[key] !== this.editableField) {
        item[key] = this.editableField;
        const { baseAmount, componentSubtotal, budgetAmount } = calculateBudget(item, this.plan);
        item.baseAmount = baseAmount;
        item.componentSubtotal = componentSubtotal;
        item.budgetAmount = budgetAmount;
        switch (key) {
          case 'unit':
            item.unitName = (item.unit && item.unit.name) || '';
            break;
          case 'conditionAssessment':
            item.conditionName = (item.conditionAssessment && item.conditionAssessment.name) || '';
            break;
          case 'fundingSource':
            item.fundingSourceName = (item.fundingSource && item.fundingSource.name) || '';
            break;
        }
        this.$emit('change', item, key);
      }
    },
    onOpen(item, key) {
      setTimeout(() => {
        this.editableItem = item;
        this.valid = true;
        this.editableField = item[key];
      }, 100);
    },
    massChage(key, value) {
      this.editableField = value;
      this.valid = true;
      this.filtered.forEach(item => {
        if (item[key]) {
          this.onSave(item, key);
        }
      });
    },
    setFilterValue(key, value) {
      if (this.$refs.data_table && Array.isArray(this.$refs.data_table.filters)) {
        const filter = this.$refs.data_table.filters.find(item => item.name === key);
        filter && typeof filter.value === typeof value && (filter.value = value);
      }
    },
    onFlagFilter(filter, callback) {
      if (filter && typeof callback === 'function') {
        let filterValue = [];
        if (this.flagFilterModel.length === 2) {
          filterValue = [true, false];
        } else if (this.flagFilterModel[0] === 'Marked') {
          filterValue = [true];
        } else if (this.flagFilterModel[0] === 'Unmarked') {
          filterValue = [false];
        }
        filter.value = filterValue;
        callback(filter);
      }
    },
    resetFlagFilter() {
      this.flagFilterModel = [];
    },
  },
};

export default {
  name: 'EditDialog',
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    showDialog() {
      this.isActive = true;
      this.$nextTick(() => {
        this.$refs.dialog.isActive = true;
      });
    },
    onOpen(event) {
      this.$emit('open', event);
    },
    onClose(event) {
      this.isActive = false;
      this.$emit('close', event);
    },
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"main_modal",attrs:{"fullscreen":"","persistent":true,"no-click-animation":true,"closeFunction":_vm.confirmBudgetPlanning},on:{"close":_vm.onModalClose},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({attrs:{"width":"180"}},'btn',attrs,false),on),[_vm._v("Budget Planning")])]}},{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('v-row',{staticClass:"mt-3 mb-3",attrs:{"no-gutters":"","justify":"start"}},[_c('h1',{staticClass:"mr-10"},[_vm._v(" Budget Planning / "),_c('span',{on:{"click":close}},[_vm._v(_vm._s(_vm.plan.name))])]),(_vm.showGenerateReportBtn)?_c('modal',{attrs:{"title":"Generate Report","width":"380px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({staticClass:"mr-6",attrs:{"width":"110","color-type":"accent","disabled":_vm.loading || _vm.internalLoading}},'btn',attrs,false),on),[_vm._v("Report")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('components-report',{attrs:{"disableYearSlider":_vm.isHideReportSlider,"plan":_vm.plan,"components":_vm.reportComponents},on:{"done":close}})]}}],null,true)}):_vm._e(),_c('modal',{attrs:{"width":"500px","no-close":true,"persistent":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({staticClass:"mr-6",attrs:{"width":"170","disabled":_vm.loading || _vm.internalLoading || _vm.changes.length === 0}},'btn',attrs,false),on),[_vm._v("Apply to Plan")])]}},{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"modal-title mt-8"},[_vm._v("Are you sure you want to save the changes and apply them to the plan?")])]},proxy:true},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"row mt-2"},[_c('btn',{staticClass:"mr-6",on:{"click":function($event){return _vm.onApplyToPlan(close)}}},[_vm._v("Yes")]),_vm._v(" "),_c('btn',{on:{"click":close}},[_vm._v("No")])],1)]}}],null,true)}),_c('modal',{ref:"confirm_modal",attrs:{"width":"500px","no-close":true,"persistent":true},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"modal-title mt-8"},[_vm._v(" Are you sure you want to close the window? Unsaved data will be lost and not be applied to the plan ")])]},proxy:true},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"row mt-2"},[_c('btn',{staticClass:"mr-6",on:{"click":_vm.closeBudgetPopup}},[_vm._v("Yes")]),_vm._v(" "),_c('btn',{on:{"click":close}},[_vm._v("No")])],1)]}}],null,true)}),_c('btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length > 0),expression:"selected.length > 0"}],attrs:{"width":"150","color-type":"accent","text":"","disabled":_vm.loading || _vm.internalLoading},on:{"click":_vm.onReset}},[_vm._v("Reset Included")])],1)]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"budget-planning mt-2",on:{"done":close}},[_c('alert',{attrs:{"error":_vm.error}}),_c('v-expansion-panels',{attrs:{"flat":"","value":_vm.expanded,"multiple":""}},[_c('v-expansion-panel',[_c('expansion-panel-header',[_vm._v(" Clients & Components Budget ")]),_c('v-expansion-panel-content',[_c('div',{staticClass:"budget-table-container"},[_c('budget-table',{attrs:{"components":_vm.components,"changes":_vm.changes,"plan":_vm.plan,"loading":_vm.loading}})],1)])],1),_c('v-expansion-panel',[_c('expansion-panel-header',[_vm._v(" Financial Information ")]),_c('v-expansion-panel-content',[(_vm.plan && _vm.plan.financialInfo)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"financial-info-details"},[_c('plan-financial-info',{attrs:{"plan":_vm.plan}})],1)],1):_c('v-row',{attrs:{"no-gutters":""}},[_vm._v("No financial information")])],1)],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":"","justify":"end"}},[_c('modal',{attrs:{"title":"Bulk year change","width":"380px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showBulkYearChangeBtn),expression:"showBulkYearChangeBtn"}],staticClass:"ml-6",attrs:{"width":"200"}},'btn',attrs,false),on),[_vm._v("Bulk year change ")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_vm._v("` "),_c('v-select',{attrs:{"items":_vm.years,"label":"Year Set for Improvement","required":""},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}),_c('btn',{attrs:{"width":"200"},on:{"click":function($event){_vm.bulkUpdateComponentsYear(_vm.selectedYear);
                close();}}},[_vm._v("Update components ")])]}}],null,true)}),_c('budget-calculation-info'),_c('data-table-columns',{attrs:{"headers":_vm.headers,"uId":_vm.TABLE_UID},model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1),_c('component-list',{ref:"component_list",staticClass:"mt-3",attrs:{"plan":_vm.plan,"loading":_vm.loading,"components":_vm.components,"readonly":!_vm.canEdit,"changes":_vm.changes,"selected":_vm.selected,"filtered":_vm.filtered,"show-select":_vm.showGenerateReportBtn,"selected-headers":_vm.selectedHeaders},on:{"change":_vm.onChange,"select":_vm.onSelect,"filtered":_vm.onFilter,"initHeaders":_vm.onInitHeaders}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
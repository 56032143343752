import Btn from '@/components/Btn/Btn.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import validateRules from '@/services/validateRules';
import Upload from '@/components/Upload/Upload.vue';

export default {
  name: 'FacilityForm',
  components: { Btn, NumberTextField, Upload },
  props: {
    btnLabel: { type: String, required: true },
    loading: { type: Boolean, default: false },
    facility: { type: Object },
  },
  data: vm => {
    const upload = vm.facility.urlPhotos ? new File([''], vm.facility.urlPhotos, { type: 'image/plain' }) : null;
    return {
      ...validateRules,
      currentUser: null,
      upload: upload,
      nameRules: [v => !!v || 'Name is Required'],
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate()) {
        if (this.upload && this.upload.name !== this.facility.urlPhotos) {
          this.facility.photoFile = this.upload;
          this.facility.urlPhotos = '';
        }
        this.$emit('submit', this.facility);
      }
    },
  },
};

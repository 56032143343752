import PlanForm from '@/components/Plans/PlanForm/PlanForm.vue';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import PERMISSIONS from '@/enums/permissions';
import CLIENT_QUERY from '@/graphql/queries/client.gql';
export default {
  name: 'AddPlan',
  components: { PlanForm, Breadcrumbs },
  props: { clientId: { type: String } },
  apollo: {
    client: {
      query: CLIENT_QUERY,
      variables() {
        return { id: this.clientId };
      },
      error(e) {
        this.error = e;
      },
    },
  },
  data: vm => ({
    plan: {
      name: null,
      icsProjectNumber: null,
      dateOfCreation: null,
      dateOfAssessment: null,
      photosLink: null,
      description: null,
      financialInfo: null,
      components: null,
      client: { id: vm.clientId },
      isCurrent: false,
    },
    client: null,
    error: null,
    readonlyFields: ['typeOfPlan'],
  }),
  computed: {
    loading() {
      return this.$apollo.queries.client.loading;
    },
    breadcrumbs() {
      return [
        {
          text: 'Clients',
          to: { name: 'clients' },
          permission: PERMISSIONS.VIEW_CLIENTS,
        },
        ...(this.client
          ? [
              {
                text: this.client.name || '',
                to: { name: 'client-details', params: { clientId: this.clientId } },
                permission: PERMISSIONS.VIEW_CLIENT,
              },
            ]
          : []),
        {
          text: 'Create Plan',
          disabled: true,
        },
      ];
    },
  },
  methods: {
    onSubmit(plan) {
      this.plan = plan;
    },
  },
};

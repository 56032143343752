import Btn from '@/components/Btn/Btn.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import ADD_PLAN_MUTATION from '@/graphql/mutations/addPlan.gql';
import UPDATE_PLAN_MUTATION from '@/graphql/mutations/updatePlan.gql';
import { isEqual } from 'lodash';
import eventHub from '@/utils/eventHub';
import { formatDateISO } from '@/utils/dateFormatter';

const copy = (obj1, obj2) =>
  Object.keys(obj1).reduce((a, key) => ({ ...a, [key]: obj2[key] !== undefined ? obj2[key] : null }), {});

export default {
  name: 'PlanGeneralInfoForm',
  components: { Btn, DatePicker },
  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    plan: { type: Object },
  },
  data: () => ({
    form: {
      name: null,
      icsProjectNumber: null,
      dateOfCreation: null,
      dateOfAssessment: null,
      photosLink: null,
      description: null,
      isCurrent: false,
    },
    nameRules: [v => !!v || 'Name is Required'],
    dateOfCreationRules: [v => !!v || 'Date of Creation is Required'],
    internalLoading: false,
    error: null,
    changes: new Set(),
  }),
  watch: {
    plan: {
      immediate: true,
      handler(value) {
        if (value !== null) {
          this.error = null;
          this.form = copy(this.form, value);
          this.changes.clear();
          if (!value.dateOfCreation) {
            this.form.dateOfCreation = new Date().toISOString().substr(0, 10);
            this.onChange('dateOfCreation', this.form.dateOfCreation);
          } else {
            this.form.dateOfCreation = value.dateOfCreation.substr(0, 10);
          }
          if (value.dateOfAssessment) {
            this.form.dateOfAssessment = value.dateOfAssessment.substr(0, 10);
          }
        }
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onChange(key, value) {
      if (this.plan) {
        if (!isEqual(this.plan[key], value)) {
          this.changes.add(key);
        } else if (this.changes.has(key)) {
          this.changes.delete(key);
        }
      } else {
        this.changes.add(key);
      }
      this.$emit('change');
    },
    onSubmit() {
      this.error = null;
      if (this.validate() && this.changes.size > 0) {
        const {
          name,
          icsProjectNumber,
          dateOfCreation,
          dateOfAssessment,
          photosLink,
          description,
          isCurrent,
        } = this.form;
        const variables = {
          name,
          icsProjectNumber,
          dateOfCreation: formatDateISO(dateOfCreation),
          dateOfAssessment: formatDateISO(dateOfAssessment),
          photosLink,
          description,
          isCurrent,
        };
        if (!this.plan.id) {
          this.addPlan({ ...variables, clientId: this.plan.client.id });
        } else {
          this.updatePlan({ ...variables, id: this.plan.id });
        }
      } else if (this.validate() && this.changes.size === 0) {
        this.$emit('submit', this.plan);
      }
    },
    onReset() {
      this.form = copy(this.form, this.plan);
      this.error = null;
      this.$refs.form.resetValidation();
      this.changes.clear();
      this.$emit('reset');
    },
    addPlan(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: ADD_PLAN_MUTATION,
          variables: {
            input: variables,
          },
        })
        .then(({ data: { createPlan } }) => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Plan was added successfully!`,
          });
          this.$emit('submit', createPlan);
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
    updatePlan(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_PLAN_MUTATION,
          variables: {
            input: variables,
          },
        })
        .then(({ data: { updatePlan } }) => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Plan was updated successfully!`,
          });
          this.$emit('submit', updatePlan);
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
  },
};


    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Plan"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Plan"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isCurrent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"icsProjectNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"dateOfCreation"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"dateOfAssessment"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"photosLink"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"financialInfo"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FinancialInfo"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"client"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"facilities"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":303}};
    doc.loc.source = {"body":"#import \"../fragments/financialInfo.gql\"\nfragment Plan on Plan {\n  id\n  name\n  isCurrent\n  icsProjectNumber\n  dateOfCreation\n  dateOfAssessment\n  photosLink\n  description\n  financialInfo {\n    ...FinancialInfo\n  }\n  client {\n    id\n    name\n    facilities {\n      id\n      name\n    }\n  }\n  __typename\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../fragments/financialInfo.gql").definitions));


      module.exports = doc;
    

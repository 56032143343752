import Btn from '@/components/Btn/Btn.vue';

export default {
  name: 'PlansReportForm',
  components: { Btn },
  props: {
    loading: { type: Boolean, default: false },
    reportInfo: {
      type: Object,
      default: {
        name: null,
        plans: null,
      },
    },
  },
  data: () => ({
    nameRules: [v => !!v || 'Report Name is Required'],
    reportYearRange: [0, 0],
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onRangeChange() {
      this.reportInfo.startYear = this.reportYearRange[0].toString();
      this.reportInfo.endYear = this.reportYearRange[1].toString();
    },
    onSubmit() {
      if (this.validate() && this.reportInfo.plans && this.reportInfo.plans.length > 0) {
        this.$emit('submit');
      }
    },
  },
  computed: {
    minReportYear() {
      const year = this.reportStartYears.slice().sort((a, b) => a - b)[0];
      return year ? year : 1970;
    },
    maxReportYear() {
      const year = this.reportStartYears.slice().sort((a, b) => b - a)[0];
      return year ? year + 20 : 1990;
    },
    reportStartYears() {
      let years;
      try {
        years = this.reportInfo.plans.map(item => parseInt(item.financialInfo.planStartYear));
      } catch {
        years = [];
      }
      return years;
    },
  },
  watch: {
    minReportYear: {
      handler(newVal) {
        this.reportYearRange = [newVal, newVal + 9];
        this.reportInfo.startYear = this.reportYearRange[0].toString();
        this.reportInfo.endYear = this.reportYearRange[1].toString();
      },
      immediate: true,
    },
  },
};

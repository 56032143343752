var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{attrs:{"flat":"","value":_vm.expanded}},[_c('v-expansion-panel',[_c('expansion-panel-header',[_vm._v(" General Information ")]),_c('v-expansion-panel-content',[(_vm.plan)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"general-info"},[_c('definition-list',{attrs:{"data":Object.assign({}, _vm.plan, {isCurrent: _vm.plan.isCurrent ? 'Yes' : 'No'}),"labels":_vm.generalInfoLabels}})],1)],1):_c('v-row',{staticClass:"loader",class:{ '-loaded': !_vm.loading },attrs:{"no-gutters":""}},[_vm._v("No information")])],1)],1),_c('v-expansion-panel',[_c('expansion-panel-header',[_vm._v(" Financial Information ")]),_c('v-expansion-panel-content',[(_vm.plan && _vm.plan.financialInfo)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"financial-info-details"},[_c('plan-financial-info',{attrs:{"plan":_vm.plan}})],1)],1):_c('v-row',{staticClass:"loader",class:{ '-loaded': !_vm.loading },attrs:{"no-gutters":""}},[_vm._v("No financial information")])],1)],1),_c('v-expansion-panel',[_c('expansion-panel-header',[_vm._v(" Components of need ")]),_c('v-expansion-panel-content',[_c('alert',{attrs:{"error":_vm.error}}),(_vm.displayedItems && (_vm.displayedItems.length || _vm.loading))?_c('data-table-vs',{attrs:{"items":_vm.displayedItems,"headers":_vm.headers,"height":"611px","item-key":"id","loading":_vm.loading},on:{"mounted":_vm.setFlagFilterDefault,"reset":_vm.resetFlagFilter},scopedSlots:_vm._u([{key:"filter.flag",fn:function(ref){
var item = ref.item;
var callback = ref.callback;
return [_c('div',{staticClass:"select-wrapper",staticStyle:{"width":"97px"}},[_c('v-select',{ref:"flag_filter",attrs:{"outlined":"","dense":"","multiple":"","full-width":"","hide-details":"","clearable":"","items":['Marked', 'Unmarked'],"default":item},on:{"change":function($event){return _vm.onFlagFilter(item, callback)}},model:{value:(_vm.flagFilterModel),callback:function ($$v) {_vm.flagFilterModel=$$v},expression:"flagFilterModel"}})],1)]}},{key:"item.flag",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.themes.light.accent}},[_vm._v(_vm._s(item.flag ? 'fas' : 'far')+" fa-flag")])],1)]}},{key:"item.softCost",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.softCost,"ripple":false,"readonly":""}})]}},{key:"item.componentUrl",fn:function(ref){
var item = ref.item;
return [(item.componentUrl)?_c('a',{attrs:{"href":item.componentUrl,"target":"_blank"}},[_vm._v("Link to Photo")]):_vm._e()]}}],null,false,2983391968)}):_c('v-row',{attrs:{"no-gutters":""}},[_vm._v("No components")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }